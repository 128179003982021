import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import Header from './Header';

const Layout = () => {
    const location = useLocation();

    // Check if the current route is the home page or login page
    const isHomePage = location.pathname === '/';
    const isLoginPage = location.pathname === '/login';
    const isVerifyPage = location.pathname == '/verify-email'

    return (
        <div>
            {/* Render Header only if it's not the home or login page */}
            {!(isHomePage || isLoginPage || isVerifyPage) && <Header />}
            <main>
                <Outlet /> {/* This will render the nested routes */}
            </main>
        </div>
    );
};

export default Layout;
