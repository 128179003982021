import React, { createContext, useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig';
import { useNavigate } from 'react-router-dom';

// Create the Auth Context
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isBlacklisted, setIsBlacklisted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('jwtToken');
        console.log('Initial token:', token);

        // Check if a token exists before making API calls
        if (token) {
            // Check if user is blacklisted
            axiosInstance.get('/auth/check-blacklist', {
                headers: { Authorization: `Bearer ${token}` } // Use token in headers
            })
                .then(response => {
                    if (response.status === 200) {
                        setIsAuthenticated(true);
                        setIsBlacklisted(false);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 403) {
                        setIsAuthenticated(false);
                        setIsBlacklisted(true);
                        localStorage.removeItem('jwtToken');
                        localStorage.removeItem('refreshToken');
                        navigate('/login'); // Redirect to login
                    }
                });
        } else {
            // If no token is found, set isAuthenticated to false
            setIsAuthenticated(false);
            console.log('No token found, setting isAuthenticated to false');
            setIsBlacklisted(false);
        }
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, isBlacklisted }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
