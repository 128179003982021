import React from 'react';

const Dashboard = () => {

    return (
        <div className="dashboard-page">
            <h2>Welcome to the Dashboard</h2>
            {/* Dashboard content here */}
        </div>
    );
};

export default Dashboard;
