import React from 'react';
import Dashboard from '../components/Dashboard'; // Importing the Dashboard component
import AuthContext from '../context/AuthContext';

const DashboardPage = () => {
    
    return (
        <div>
            <Dashboard />
        </div>
    );
};

export default DashboardPage;
