import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProfilePage from './pages/ProfilePage';
import ErrorPage from './pages/ErrorPage';
import SearchResultsPage from './pages/SearchResultsPage';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import PrivateRoute from './components/PrivateRoute';
import Layout from './components/Layout'; // Use Layout only in certain routes
import VerificationPage from './pages/VerificationPage';
import Dashboard from './pages/DashboardPage';
import { AuthProvider  } from './context/AuthContext';
import './App.css';

function App() {
    return (
            <Router>
                <AuthProvider>
                <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<Home/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/verify-email" element={<VerificationPage />} />

                    {/* Protected routes using Layout and PrivateRoute */}
                    <Route element={<Layout />}>
                        <Route element={<PrivateRoute />}>
                            <Route path="/profile/:username" element={<ProfilePage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                            <Route path="/search/:query" element={<SearchResultsPage />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                        </Route>
                    </Route>

                    {/* Catch-all route for 404 */}
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
                <ToastContainer />
                </AuthProvider>
            </Router>
    );
}

export default App;
