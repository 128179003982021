import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { toast } from 'react-toastify';

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is already logged in
        const token = localStorage.getItem('token');
        if (token) {
            // Redirect to dashboard page if already logged in
            navigate('/dashboard');
        }
    }, [navigate]);

    function handleLoginSuccess(isVerified) {
        if (isVerified) {
            navigate('/dashboard');
        } else {
            toast.info('Please verify your email.');
            navigate('/verify-email'); // Explicitly handle redirection
        }
    }

    return (
        <div className="login-page">
            <div className="login-container">
                <h2>Login</h2>
                <LoginForm onLoginSuccess={handleLoginSuccess} />
            </div>
        </div>
    );
};

export default Login;
