import React from 'react';
import ReactDOM from 'react-dom'; // Standard import for React 17
import App from './App'; // Main App component
import './index.css'; // CSS file for styling

// Rendering the App component into the root div in index.html
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root') // Reference to the root div in your HTML
);
