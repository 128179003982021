import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct usage without destructuring
import AuthContext from '../context/AuthContext';

// Utility function to check if JWT token is valid
const isTokenValid = (token) => {
    try {
        const decodedToken = jwtDecode(token); // Correctly decode the token using jwt-decode
        const currentTime = Date.now() / 1000; // Current time in seconds

        // Check if token is expired
        return decodedToken.exp > currentTime;
    } catch (error) {
        // If decoding fails, token is considered invalid
        return false;
    }
};

const PrivateRoute = () => {
    const { isAuthenticated, isBlacklisted } = useContext(AuthContext);
    const token = localStorage.getItem('jwtToken');
    const tokenValid = token && isTokenValid(token);

    console.log('Token:', token);
    console.log('Token Valid:', tokenValid);
    console.log('Is Authenticated:', isAuthenticated);
    console.log('Is Blacklisted:', isBlacklisted);

    if (tokenValid && isAuthenticated && !isBlacklisted) {
        return <Outlet />;
    } else {
        return <Navigate to="/login" />;
    }
};

export default PrivateRoute;
