import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../services/axiosConfig'; // Axios instance configured with JWT
import RichTextEditor from '../components/RichTextEditor';
import DisplayRichText from '../components/DisplayRichText';
import Header from '../components/Header';
import Modal from 'react-modal'; // Use react-modal for smooth popup
import AuthContext from '../context/AuthContext';


// Style for the modal
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const ProfilePage = () => {
    const { username } = useParams(); // Extract username from URL
    const [profileData, setProfileData] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState('');
    const [newEntry, setNewEntry] = useState({});
    const [errors, setErrors] = useState({});
    

    useEffect(() => {
        const endpoint = username ? `/profile/${username}` : '/profile';

        // Fetch profile data based on endpoint
        axiosInstance.get(`/user${endpoint}`)
            .then(response => {
                setProfileData(response.data.user || response.data); // Handle both routes
                setCanEdit(response.data.canEdit || !username); // Allow edit if canEdit or no specific username
            })
            .catch(error => {
                console.error('Error fetching profile data', error);
                alert('Failed to fetch profile data');
            });
    }, [username]);

    const handleEdit = (field, value, index = null) => {
        if (index !== null) {
            setProfileData(prevState => ({
                ...prevState,
                [field]: prevState[field].map((item, idx) =>
                    idx === index ? { ...item, description: value } : item
                )
            }));
        } else {
            setProfileData(prevState => ({
                ...prevState,
                [field]: value
            }));
        }
    };

    const handleSave = () => {
        axiosInstance.put(`/profile/${profileData.username}`, profileData)
            .then(response => {
                setProfileData(response.data); // Update with saved data
                alert('Changes saved successfully');
            })
            .catch(error => {
                console.error('Error saving profile data', error);
                alert('Failed to save changes');
            });
    };

    const openModal = (section) => {
        setCurrentSection(section);
        setNewEntry({});
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setErrors({});
    };

    const handleNewEntryChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewEntry(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const validateEntry = () => {
        let valid = true;
        let newErrors = {};

        if (currentSection === 'education' || currentSection === 'experience') {
            if (!newEntry.startYear || isNaN(newEntry.startYear)) {
                newErrors.startYear = 'Start year must be a number';
                valid = false;
            }
            if (!newEntry.ongoing && (!newEntry.endYear || isNaN(newEntry.endYear))) {
                newErrors.endYear = 'End year must be a number if not ongoing';
                valid = false;
            }
        }

        setErrors(newErrors);
        return valid;
    };

    const handleAddNewEntry = () => {
        if (validateEntry()) {
            if (currentSection && profileData) {
                setProfileData(prevState => ({
                    ...prevState,
                    [currentSection]: [...(prevState[currentSection] || []), newEntry]
                }));
                closeModal();
            }
        }
    };

    if (!profileData) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <Header />
            <div className="container mt-5">
                <h1>Profile: {profileData.username}</h1>
                <p>Last Login: {new Date(profileData.lastlogintime).toLocaleString()}</p>

                {/* Personal Details Section */}
                <div className="profile-section">
                    <h3>Personal Details</h3>
                    {canEdit ? (
                        <>
                            <div className="form-group">
                                <label>First Name:</label>
                                <input
                                    type="text"
                                    value={profileData.firstName || ''}
                                    onChange={(e) => handleEdit('firstName', e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Last Name:</label>
                                <input
                                    type="text"
                                    value={profileData.lastName || ''}
                                    onChange={(e) => handleEdit('lastName', e.target.value)}
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group">
                                <label>Gender:</label>
                                <select
                                    value={profileData.gender || ''}
                                    onChange={(e) => handleEdit('gender', e.target.value)}
                                    className="form-control"
                                >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Others">Others</option>
                                    <option value="Prefer not to answer">Prefer not to answer</option>
                                </select>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>First Name: {profileData.firstName}</p>
                            <p>Last Name: {profileData.lastName}</p>
                            <p>Gender: {profileData.gender}</p>
                        </>
                    )}
                </div>

                {/* Bio Section */}
                <div className="profile-section">
                    <h3>Professional Bio</h3>
                    {canEdit ? (
                        <RichTextEditor
                            initialValue={profileData.bio || ''}
                            onSave={(value) => handleEdit('bio', value)}
                        />
                    ) : (
                        <DisplayRichText htmlContent={profileData.bio || ''} />
                    )}
                </div>

                {/* Education Section */}
                <div className="profile-section">
                    <h3>Education</h3>
                    {profileData.education && profileData.education.length > 0 ? (
                        profileData.education.map((edu, index) => (
                            <div key={index} className="form-group">
                                <h4>{edu.degree} at {edu.institution}</h4>
                                {canEdit ? (
                                    <RichTextEditor
                                        initialValue={edu.description || ''}
                                        onSave={(value) => handleEdit('education', value, index)}
                                    />
                                ) : (
                                    <DisplayRichText htmlContent={edu.description || ''} />
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                    {canEdit && <button onClick={() => openModal('education')} className="btn btn-success">Add +</button>}
                </div>

                {/* Experience Section */}
                <div className="profile-section">
                    <h3>Professional Experience</h3>
                    {profileData.experience && profileData.experience.length > 0 ? (
                        profileData.experience.map((exp, index) => (
                            <div key={index} className="form-group">
                                <h4>{exp.title} at {exp.company}</h4>
                                {canEdit ? (
                                    <RichTextEditor
                                        initialValue={exp.description || ''}
                                        onSave={(value) => handleEdit('experience', value, index)}
                                    />
                                ) : (
                                    <DisplayRichText htmlContent={exp.description || ''} />
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                    {canEdit && <button onClick={() => openModal('experience')} className="btn btn-success">Add +</button>}
                </div>

                {/* Certifications Section */}
                <div className="profile-section">
                    <h3>Certifications</h3>
                    {profileData.certifications && profileData.certifications.length > 0 ? (
                        profileData.certifications.map((cert, index) => (
                            <div key={index} className="form-group">
                                <h4>{cert.name} from {cert.organization}</h4>
                                <p>Issued on: {cert.issueDate ? new Date(cert.issueDate).toDateString() : ''}</p>
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                    {canEdit && <button onClick={() => openModal('certifications')} className="btn btn-success">Add +</button>}
                </div>

                {/* Projects Section */}
                <div className="profile-section">
                    <h3>Projects</h3>
                    {profileData.projects && profileData.projects.length > 0 ? (
                        profileData.projects.map((project, index) => (
                            <div key={index} className="form-group">
                                <h4>{project.title}</h4>
                                {canEdit ? (
                                    <RichTextEditor
                                        initialValue={project.description || ''}
                                        onSave={(value) => handleEdit('projects', value, index)}
                                    />
                                ) : (
                                    <DisplayRichText htmlContent={project.description || ''} />
                                )}
                                <a href={project.url} target="_blank" rel="noopener noreferrer">{project.url}</a>
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                    {canEdit && <button onClick={() => openModal('projects')} className="btn btn-success">Add +</button>}
                </div>

                {/* Volunteering Section */}
                <div className="profile-section">
                    <h3>Volunteering</h3>
                    {profileData.volunteering && profileData.volunteering.length > 0 ? (
                        profileData.volunteering.map((vol, index) => (
                            <div key={index} className="form-group">
                                <h4>{vol.title} at {vol.location}</h4>
                                {canEdit ? (
                                    <RichTextEditor
                                        initialValue={vol.description || ''}
                                        onSave={(value) => handleEdit('volunteering', value, index)}
                                    />
                                ) : (
                                    <DisplayRichText htmlContent={vol.description || ''} />
                                )}
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                    {canEdit && <button onClick={() => openModal('volunteering')} className="btn btn-success">Add +</button>}
                </div>

                {/* Journals and Publications Section */}
                <div className="profile-section">
                    <h3>Journals and Publications</h3>
                    {profileData.journalsAndPublications && profileData.journalsAndPublications.length > 0 ? (
                        profileData.journalsAndPublications.map((pub, index) => (
                            <div key={index} className="form-group">
                                <h4>{pub.title} published in {pub.journalName}</h4>
                                {canEdit ? (
                                    <RichTextEditor
                                        initialValue={pub.description || ''}
                                        onSave={(value) => handleEdit('journalsAndPublications', value, index)}
                                    />
                                ) : (
                                    <DisplayRichText htmlContent={pub.description || ''} />
                                )}
                                <a href={pub.url} target="_blank" rel="noopener noreferrer">{pub.url}</a>
                            </div>
                        ))
                    ) : (
                        <p>No data available</p>
                    )}
                    {canEdit && <button onClick={() => openModal('journalsAndPublications')} className="btn btn-success">Add +</button>}
                </div>

                {canEdit && (
                    <button onClick={handleSave} className="btn btn-primary mt-3">Save Changes</button>
                )}

                {/* Modal for adding new entries */}
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Add New Entry"
                >
                    <h2>Add New {currentSection}</h2>
                    <div className="form-group">
                        <label>Title:</label>
                        <input
                            type="text"
                            name="title"
                            onChange={handleNewEntryChange}
                            className="form-control"
                        />
                    </div>
                    {currentSection === 'education' || currentSection === 'experience' ? (
                        <>
                            <div className="form-group">
                                <label>Start Year:</label>
                                <input
                                    type="text"
                                    name="startYear"
                                    onChange={handleNewEntryChange}
                                    className="form-control"
                                />
                                {errors.startYear && <span className="text-danger">{errors.startYear}</span>}
                            </div>
                            <div className="form-group">
                                <label>Ongoing:</label>
                                <input
                                    type="checkbox"
                                    name="ongoing"
                                    onChange={handleNewEntryChange}
                                    className="form-check-input"
                                />
                            </div>
                            {!newEntry.ongoing && (
                                <div className="form-group">
                                    <label>End Year:</label>
                                    <input
                                        type="text"
                                        name="endYear"
                                        onChange={handleNewEntryChange}
                                        className="form-control"
                                    />
                                    {errors.endYear && <span className="text-danger">{errors.endYear}</span>}
                                </div>
                            )}
                        </>
                    ) : null}
                    <div className="form-group">
                        <label>Description:</label>
                        <textarea
                            name="description"
                            onChange={handleNewEntryChange}
                            className="form-control"
                        />
                    </div>
                    <button onClick={handleAddNewEntry} className="btn btn-primary">Add</button>
                    <button onClick={closeModal} className="btn btn-secondary">Cancel</button>
                </Modal>
            </div>
        </div>
    );
};

export default ProfilePage;
