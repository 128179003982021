import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../context/AuthContext';

const Home = () => {
    const { isAuthenticated } = useContext(AuthContext);

    return (
        <div>
            <div className="container mt-5">
                <div className="jumbotron text-center">
                    <h1 className="display-4">Welcome to Hire Me</h1>
                    <p className="lead">Your one-stop solution for building and managing professional profiles.</p>
                    {!isAuthenticated ? (
                        <div>
                            <Link to="/signup" className="btn btn-primary btn-lg mx-2">Sign Up</Link>
                            <Link to="/login" className="btn btn-secondary btn-lg mx-2">Sign In</Link>
                        </div>
                    ) : (
                        <p className="lead">You are already signed in.</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;
