import React, { useState } from 'react';
import axiosInstance from '../services/axiosConfig'; // Axios instance configured with JWT
import { toast } from 'react-toastify';
import './LoginForm.css'; // Ensure you have CSS for styling

const LoginForm = ({ onLoginSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        try {
            const response = await axiosInstance.post('/auth/login', { email, password });
            const { token, refreshToken, isVerified, redirect, lastlogintime} = response.data;
            // Save tokens to local storage
            localStorage.setItem('jwtToken', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('userEmail', email);
            localStorage.setItem('lastlogintime', lastlogintime);

            // Check if a redirect is needed
            if (redirect) {
                onLoginSuccess(false); // This informs Login.js that the user is unverified
            } else {
                onLoginSuccess(true); // User is verified, proceed normally
            }
        } catch (error) {
            toast.error('Login failed. Check your credentials.');
        }
    };

    return (
        <div className="login-form">
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
                className="login-input"
            />
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                required
                className="login-input"
            />
            <button onClick={handleLogin} className="login-button">Login</button>
        </div>
    );
};

export default LoginForm;
