import React from 'react';
import SignUpForm from '../components/SignUpForm';
import LoginForm from '../components/LoginForm';

const SignUp = () => {
    return (
        <div>
            <SignUpForm/>
        </div>
    );
};

export default SignUp;