import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css'; // Assuming you have some CSS for styling

const Header = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate(); // Use useNavigate for programmatic navigation

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery) {
            // Navigate to a search results page or handle search logic
            navigate(`/search/${searchQuery}`);
        }
    };

    const handleLogout = () => {
        // Clear JWT token from local storage
        localStorage.removeItem('jwtToken');
        // Redirect to login page
        navigate('/login');
    };

    return (
        <header className="app-header">
            <nav>
                <Link to="/">Home / Hire Me</Link>
                <Link to="/profile">Profile</Link>

                {/* Search Bar */}
                <form onSubmit={handleSearch} className="search-form">
                    <input
                        type="text"
                        placeholder="Search usernames..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit">Search</button>
                </form>

                {/* Settings Dropdown */}
                <div className="settings-dropdown">
                    <button className="settings-button">Settings</button>
                    <div className="dropdown-content">
                        <Link to="/settings">Settings</Link>
                        <button onClick={handleLogout}>Logout</button>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
