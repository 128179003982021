import React, { useState, useEffect } from 'react';
import axiosInstance from '../services/axiosConfig'; // Axios instance configured with JWT
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // For displaying toast messages
import './VerificationPage.css'; // Assuming you have a CSS file for styling
//import useAuth from '../hooks/useAuth'; // Custom hook to check authentication

const VerificationPage = () => {
    //useAuth();
    const [verificationCode, setVerificationCode] = useState('');
    const [resendTimer, setResendTimer] = useState(120); // 2-minute countdown for resend
    const [resendEnabled, setResendEnabled] = useState(false);
    const [resendCount, setResendCount] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        // Countdown timer logic
        const interval = setInterval(() => {
            setResendTimer((prevTimer) => {
                if (prevTimer > 0) return prevTimer - 1;
                setResendEnabled(true);
                return 0;
            });
        }, 1000);
        return () => clearInterval(interval); // Cleanup on component unmount
    }, []);

    const handleVerification = async () => {
        try {
            const response = await axiosInstance.post('/api/verify-email', { code: verificationCode });
            toast.success(response.data.message);
            navigate('/dashboard'); // Navigate to the dashboard or appropriate page
        } catch (error) {
            toast.error('Verification failed. Please try again.');
        }
    };

    const handleResendCode = async () => {
        if (resendCount >= 5) {
            toast.error('Maximum resend attempts reached.');
            return;
        }

        try {
            await axiosInstance.post('/api/resend-token', { email: localStorage.getItem('userEmail') });
            toast.success('New verification code sent to your email.');
            setResendCount(resendCount + 1);
            setResendTimer(120); // Reset the timer
            setResendEnabled(false);
        } catch (error) {
            toast.error('Error sending verification code. Please try again later.');
        }
    };

    return (
        <div className="verification-page">
            <h2>Email Verification</h2>
            <p>Please enter the verification code sent to your email.</p>
            <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Enter verification code"
                className="verification-input"
            />
            <button onClick={handleVerification} className="verification-button">
                Verify
            </button>

            <div className="resend-section">
                {resendEnabled ? (
                    <button onClick={handleResendCode} className="resend-button">
                        Resend Code
                    </button>
                ) : (
                    <p>Resend code in {resendTimer} seconds</p>
                )}
            </div>

            <p>Resend attempts left: {5 - resendCount}</p>
        </div>
    );
};

export default VerificationPage;
