import axios from 'axios';
import { jwtDecode }from 'jwt-decode';

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:5010/api/v1',  // Your backend API base URL
});

// Function to refresh JWT token
const refreshAuthToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
        const response = await axiosInstance.post('/auth/refresh-token', { token: refreshToken });
        localStorage.setItem('jwtToken', response.data.token);
        return response.data.token;
    } catch (error) {
        console.error('Failed to refresh token', error);
        throw error;
    }
};

// Add a request interceptor to include the JWT token
axiosInstance.interceptors.request.use(
    async (config) => {
        let token = localStorage.getItem('jwtToken');
        if (token)
            {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000;
    
                // Check if the token is expired or will expire soon
                if (decodedToken.exp < currentTime + 300) { // Token will expire in the next 5 minutes
                    console.log("in here expired");
                    try {
                        token = await refreshAuthToken();
                    } catch (error) {
                        // Handle token refresh failure (e.g., logout user)
                        localStorage.removeItem('jwtToken');
                        localStorage.removeItem('refreshToken');
                        window.location.href = '/login';
                        return Promise.reject(error);
                    }
                }
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
