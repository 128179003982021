import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';

const SearchResultsPage = () => {
    const { query } = useParams();
    // Fetch and display search results based on the query
    return (
        <div>
            <h1>Search Results for "{query}"</h1>
            {/* Implement search logic to fetch and display results */}
        </div>
    );
};

export default SearchResultsPage;
