import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const RichTextEditor = ({ initialValue, onSave }) => {
    const [value, setValue] = useState(initialValue || '');

    const handleSave = () => {
        onSave(value);
    };

    return (
        <div>
            <ReactQuill value={value} onChange={setValue} />
            <button onClick={handleSave} className="btn btn-primary mt-2">Save</button>
        </div>
    );
};

export default RichTextEditor;
